import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Book from '@material-ui/icons/Book';
import CloudDownload from '@material-ui/icons/CloudDownload';
import BarChartIcon from '@material-ui/icons/BarChart';
import LocalAtm from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';
import ListIcon from '@material-ui/icons/List';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import AttachMoney from '@material-ui/icons/AttachMoney';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SettingsSystemDaydream from '@material-ui/icons/SettingsSystemDaydream';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as QQ } from "./QQ.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import config from "./config";

const useStyles = makeStyles((theme) => ({
  subHeader: {
    fontFamily: "Noto Sans SC",
    fontSize: "16px",
  },
  listItem: {
    fontFamily: "Noto Sans SC",
    fontSize: "16px",
  }
}));

export function MainListItems({ menuCallback, sub, id }) {

  const classes = useStyles();

  return (
    <div>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('dashboard')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        数据面板
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('workers')}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        矿机列表
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('withdrawConfig')}>
        <ListItemIcon>
          <AttachMoney />
        </ListItemIcon>
        提现设置
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('transfer')}>
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        站内转赠
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('remoteSetting')}>
        <ListItemIcon>
          <SettingsSystemDaydream />
        </ListItemIcon>
        远程群控
      </ListItem>
      <ListItem button className={classes.listItem} onClick={() => menuCallback('hubManager')}>
        <ListItemIcon>
          <SupervisorAccount />
        </ListItemIcon>
        网维管理
      </ListItem>
      {
        !sub &&
        <ListItem button className={classes.listItem} onClick={() => menuCallback('userConfig')}>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          个人账号
        </ListItem>
      }
      {
        config.adminList.includes(id) && 
        <ListItem button className={classes.listItem} onClick={() => menuCallback('admin')}>
          <ListItemIcon>
            <ShowChartIcon />
          </ListItemIcon>
          后台统计
        </ListItem>
      }
      {
        config.adminList.includes(id) && 
        <ListItem button className={classes.listItem} onClick={() => menuCallback('hubReview')}>
          <ListItemIcon>
            <DoneAllIcon />
          </ListItemIcon>
          网维审核
        </ListItem>
      }
      <p/>
      <Divider />
      <p/>
      <ListItem button className={classes.listItem}  onClick={() => {
        menuCallback('tutorial');
        window.open('https://www.yuque.com/skypool/uox3k7/gcuq29','_blank');
      }}>
        <ListItemIcon>
          <Book />
        </ListItemIcon>
        图文教程
      </ListItem>
      <ListItem button className={classes.listItem}  onClick={() => {
        window.open('https://www.yuque.com/skypool/uox3k7/tc9fz2','_blank');
      }}>
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        程序下载
      </ListItem>
    </div>
  );
}

export function SecondaryListItems({open}) {

  const classes = useStyles();
  const openSkypool = () => {
    window.open('https://skypool.xyz', "_blank");
  }

  return (
    <div>
      <ListItem button onClick={openSkypool} className={classes.listItem}>
        <ListItemIcon>
          <LocalAtm />
        </ListItemIcon>
        天池矿池
      </ListItem>
        <br/>
        <center>
        {/* eslint-disable-next-line */}
        <a style={{textDecoration: "none"}} target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=E4ife5reyv4ObCFXOA_WVcs-89hnkxag&jump_from=webapi" rel="noreferrer">
          <SvgIcon viewBox="0 0 48 48" fontSize="large">
            <QQ />
          </SvgIcon>
          {open && <p style={{margin: "0px", color:"#000000DE", fontFamily:"Noto Sans SC"}}>QQ群：948383419</p>}
        </a>
        </center>
    </div>
  );
}