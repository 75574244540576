import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function ReferralTotal({data, btc2cny}) {

  const [rows, setRows] = useState([]);
  const [hideZero, setHideZero] = useState(true);

  useEffect(() => {
    const temp = data;
    setRows(temp);
  }, [data]);

  const handleHideZero = (event) => {
    setHideZero(event.target.checked);
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        推荐总收益
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={hideZero}
            onChange={handleHideZero}
            name="hideZero"
            color="primary"
          />
        }
        label="隐藏零收益"
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>BTC</TableCell>
            <TableCell>转换 CNY</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.filter(v => {return (v.btcBalance > 0 || !hideZero)}).map((row) => (
            <TableRow key={row.childId}>
              <TableCell>{row.childId}</TableCell>
              <TableCell>{row.btcBalance / 1e8}</TableCell>
              <TableCell>{(row.btcBalance / 1e8 * btc2cny).toFixed(2)}元</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}