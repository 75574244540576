import React from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

export default function Tutorial() {

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        教程
      </Typography>
      <List>
        <ListItem>
          <Typography component="p" variant="body1">
            <a href="https://www.yuque.com/skypool/uox3k7/tc9fz2" target="_blank" rel="noreferrer">天池矿工网吧版下载</a>
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="p" variant="body1">
          <b>欢迎任何人参与 <a href="https://www.yuque.com/skypool/uox3k7/ngaeqn" target="_blank" rel="noreferrer">天池网吧版全民推广计划</a>，帮助他人上挖矿，获得分红收益。</b>
          <b>天池矿工网吧版QQ群：948383419，目前天池矿工处于推广中，使用和提现问题联系推荐人或者群主。</b>
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="p" variant="body1">
            天池矿工网吧版核心功能，根据当前闲置机器的CPU / 显卡使用强度和游戏帧数，动态调节挖矿，在确保机器不卡的情况下挖收益最高的币，目前接入 ETC/RVN/ERG/CFX/KAS 五个显卡币种和 XMR CPU 币种，统一结算成 BTC 提现按 RMB 提现。
          </Typography>
        </ListItem>
        <ListItem>
          <Typography component="p" variant="body1">
            登录成功后，在数据面板账户余额中查看 ID，复制 ID 到天池矿工软件配置文件中直接启动即可。详细使用方式<a href="https://www.yuque.com/skypool/uox3k7/gcuq29" target="_blank" rel="noreferrer">参见教程</a>。
          </Typography>
        </ListItem>
        {/* <ListItem>
          <Typography component="p" variant="body1">
            矿池服务器和软件开发本身需要成本，以及在兑换成比特币和人民币的过程中会有一定交易所手续费和价格波动风险，这里所有的转换损失由天池承担，矿工获得的是最佳的理论值收益。因此矿工目前获得总理论值收益的 97%，天池收取 3% 的佣金，如果由推荐人推荐，则推荐人获得 2% 的分红，天池收取 1%。无论是否有推荐人，矿工获得的收益不变。
          </Typography>
        </ListItem> */}
      </List>
    </React.Fragment>
  );
}