import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import axios from 'axios';
import config from './config';
import Button from '@material-ui/core/Button';
import _ from 'underscore';

const useStyles = makeStyles((theme) => ({
  table: {
    width: '800px',
    margin: '15px 0 0'
  },
  tableCell: {
    padding: '5px 16px',
    lineHeight: '30px'
  }
}));

export default function HubReview () {
  const classes = useStyles();

  const [hubPermission, setHubPermission] = useState([]);

  const getHubPermission = async () => {
    const ret = await axios.get(`${config.API}/v1/admin/getHubPermission`, {
      withCredentials: true,
    });
    if (!ret.data.success) return;
    setHubPermission(ret.data.data || []);
  }

  const accept = async (hubId) => {
    const ret = await axios.get(`${config.API}/v1/admin/updateHubPermission?type=accept&hubId=${hubId}`, {
      withCredentials: true
    });
    if (!ret.data.success) return;
    const newHubPermission = _.reject(hubPermission, (item) => item.id === hubId);
    setHubPermission(newHubPermission);
  }

  const reject = async (hubId) => {
    const ret = await axios.get(`${config.API}/v1/admin/updateHubPermission?type=reject&hubId=${hubId}`, {
      withCredentials: true
    });
    if (!ret.data.success) return;
    const newHubPermission = _.reject(hubPermission, (item) => item.id === hubId);
    setHubPermission(newHubPermission);
  }

  useState(() => {
    getHubPermission();
  }, []);
  
  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        网维审核
      </Typography>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>id</TableCell>
            <TableCell className={classes.tableCell}>name</TableCell>
            <TableCell className={classes.tableCell}>city</TableCell>
            <TableCell className={classes.tableCell}>barNum</TableCell>
            <TableCell className={classes.tableCell}>通过</TableCell>
            <TableCell className={classes.tableCell}>忽略</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { hubPermission.map((row) => (
            <TableRow key={row.name}>
              <TableCell className={classes.tableCell} component="th" scope="row">
                { row.id }
              </TableCell>
              <TableCell className={classes.tableCell}>
                { row.name }
              </TableCell>
              <TableCell className={classes.tableCell}>
                { row.city }
              </TableCell>
              <TableCell className={classes.tableCell}>
                { row.barNum }
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Button 
                  onClick={() => accept(row.id)} 
                  variant="contained" 
                  color="primary"
                >通过</Button>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Button 
                  onClick={() => reject(row.id)} 
                  variant="contained" 
                  color="secondary"
                >忽略</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}