import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import config from './config';
import { Column } from '@ant-design/charts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import Message from './component/Message';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  secTitle: {
    margin: '10px 0 10px'
  },
  secTitle2: {
    margin: '20px 0 10px'
  },
  table: {
    width: '600px',
    margin: '15px 0 0'
  },
  tableCell: {
    padding: '5px 16px',
    lineHeight: '30px'
  },
  basicBlock: {
    margin: '0 0 15px'
  }
}));

export default function Admin ({ price }) {
  const classes = useStyles();

  const [incomeRows, setIncomeRows] = useState([]);
  const [incomeStartTime, setIncomeStartTime] = useState(null);
  const [incomeEndTime, setIncomeEndTime] = useState(null);
  const [withdrawRows, setWithdrawRows] = useState([]);
  const [withdrawStartTime, setWithdrawStartTime] = useState(null);
  const [withdrawEndTime, setWithdrawEndTime] = useState(null);
  const [rankRows, setRankRows] = useState([]);
  const [rankStartTime, setRankStartTime] = useState(null);
  const [rankEndTime, setRankEndTime] = useState(null);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [incomeGraph, setIncomeGraph] = useState([]);
  const [withdrawalGraph, setWithdrawalGraph] = useState([]);
  const [btcMap2, setBtcMap2] = useState({});
  const [btcMap, setBtcMap] = useState({});
  const [recentRows, setRecentRows] = useState([]);

  const names = ['etc', 'rvn', 'erg', 'cfx', 'kas', 'xmr', 'btc'];

  const processIncomeRows = async () => {
    if (!incomeStartTime) return setOpen(true);
    if (!incomeEndTime) return setOpen(true);
    if (!Date.parse(incomeStartTime)) return setOpen(true);
    if (!Date.parse(incomeEndTime)) return setOpen(true);
    const startTime = Date.parse(incomeStartTime + ' 00:00');
    const endTime = Date.parse(incomeEndTime + ' 00:00');
    const ret = await axios.get(`${config.API}/v1/admin/getIncome?startTime=${startTime}&endTime=${endTime}`, {
      withCredentials: true,
    });
    if (!ret.data.success) return;
    const resData = ret.data.data || [];
    const rows = [];
    let btcTotal = 0;
    let cnyTotal = 0;
    for (const name of names) {
      rows.push({ 
        name, 
        amount: (resData[`${name}Sum`] || 0) / 1e8,
        btc: (resData[`${name}Sum`] || 0) / 1e8 * (price[`${name}2btc`] || 1),
        cny: (resData[`${name}Sum`] || 0) / 1e8 * (price[`${name}2btc`] || 1) * price['btc2cny']
      })
      if (name !== 'btc') 
        btcTotal += (resData[`${name}Sum`] || 0) / 1e8 * (price[`${name}2btc`] || 1);
      if (name !== 'btc') 
        cnyTotal += (resData[`${name}Sum`] || 0) / 1e8 * (price[`${name}2btc`] || 1) * price['btc2cny'];
    }
    for (let i = 0; i < rows.length; i++) {
      rows[i].btcShare = rows[i].btc / btcTotal;
      rows[i].cnyShare = rows[i].cny / cnyTotal;
    }
    setIncomeRows(rows);
  }

  const processWithdrawRows = async () => {
    if (!withdrawStartTime) return setOpen(true);
    if (!withdrawEndTime) return setOpen(true);
    if (!Date.parse(withdrawStartTime)) return setOpen(true);
    if (!Date.parse(withdrawEndTime)) return setOpen(true);
    const startTime = Date.parse(withdrawStartTime);
    const endTime = Date.parse(withdrawEndTime);
    const ret = await axios.get(`${config.API}/v1/admin/getWithdrawal?startTime=${startTime}&endTime=${endTime}`, {
      withCredentials: true,
    });
    if (!ret.data.success) return;
    const resData = ret.data.data || [];
    const baseCny = resData.baseCny || {};
    const baseUsd = resData.baseUsd || {};
    const baseTotal = resData.baseTotal || {};
    const rows = [
      { baseUnit: 'CNY', cny: baseCny.cny, btc: (baseCny.btc || 0) / 1e8, usd: '-' },
      { baseUnit: 'USD', cny: baseUsd.cny, btc: (baseUsd.btc || 0) / 1e8, usd: baseUsd.usd },
      { baseUnit: '总计', cny: baseTotal.cny, btc: (baseTotal.btc || 0) / 1e8, usd: '-' }
    ];
    setWithdrawRows(rows);
  }

  const processRankRows = async () => {
    if (!rankStartTime) return setOpen(true);
    if (!rankEndTime) return setOpen(true);
    if (!Date.parse(rankStartTime)) return setOpen(true);
    if (!Date.parse(rankEndTime)) return setOpen(true);
    const startTime = Date.parse(rankStartTime);
    const endTime = Date.parse(rankEndTime);
    const ret = await axios.get(
      `${config.API}/v1/admin/getWithdrawalRank?startTime=${startTime}&endTime=${endTime}&limit=${limit}`, {
      withCredentials: true,
    });
    if (!ret.data.success) return;
    const rows = ret.data.data || [];
    setRankRows(rows);
  }

  const processRecentWithdraw = async () => {
    const endTime = Date.now();
    const ret = await axios.get(
      `${config.API}/v1/admin/getRecentWithdrawal?endTime=${endTime}`, {
      withCredentials: true,
    });
    if (!ret?.data?.success) return;
    const rows = ret?.data?.data || [];
    setRecentRows(rows);
  }

  const fmoney = (s, d) => {
    d = d || 2;
    // eslint-disable-next-line
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(d) + "";
    var l = s.split(".")[0].split("").reverse(),
        r = s.split(".")[1];
    let t = "";
    for (let i = 0; i < l.length; i++) {
        t += l[i] + ((i + 1) % 3 === 0 && (i + 1) !== l.length ? "," : "");
    }
    return t.split("").reverse().join("") + (r ? `.${r}`: '');
  }

  const formatTime = (timeObj) => {
    const date = timeObj.getDate();
    const month = timeObj.getMonth() + 1;
    const toTwoDigits = (num) => {
      return num < 10 ? `0${num}`: `${num}`
    }
    return `${toTwoDigits(month)}-${toTwoDigits(date)}`
  }

  const processIncomeGraph = async () => {
    const time = (new Date()).getTime();
    const ret = await axios.get(`${config.API}/v1/admin/getIncomeGraph?time=${time}`, {
      withCredentials: true,
    });
    if (!ret.data.success) return;
    const resData = ret.data.data || [];
    const incomeGraph = [];
    const fieldList = ['etc', 'xmr', 'erg', 'cfx', 'kas', 'rvn'];
    const toBtcValue = (value, name) => {
      const btc = (value || 0) / 1e8 * (price[`${name}2btc`] || 1)
      return parseFloat(btc.toFixed(5))
    }
    const btcMap = {};
    for (const day of resData) {
      for (const field of fieldList) {
        incomeGraph.push({
          date: formatTime(new Date(day.date)),
          value: toBtcValue(day[`${field}Sum`], field),
          type: field
        });
        const btc = ((day.btcSum || 0) / 1e8).toFixed(2);
        const cny = ((day.btcSum || 0) / 1e8 * price['btc2cny']).toFixed(2);
        btcMap[formatTime(new Date(day.date))] = 
          `${btc} BTC ≈ ${cny}元`;
      }
    }
    setIncomeGraph(incomeGraph);
    setBtcMap(btcMap);
  }

  const processWithdrawalGraph = async () => {
    const ret = await axios.get(`${config.API}/v1/admin/getWithdrawalGraph`, {
      withCredentials: true,
    });
    if (!ret.data.success) return;
    const resData = ret.data.data || [];
    const withdrawalGraph = [];
    const btcMap2 = {};
    for (const day of resData) {
      withdrawalGraph.push({
        date: formatTime(new Date(day.date)),
        value: day.cnySum,
        type: 'btc'
      })
      btcMap2[formatTime(new Date(day.date))] = (day[`btcSum`] || 0) / 1e8;
    }
    setWithdrawalGraph(withdrawalGraph);
    setBtcMap2(btcMap2);
  }

  useEffect(() => {
    processIncomeGraph();
    processWithdrawalGraph();
    // eslint-disable-next-line
  }, []);

  const configG = {
    data: incomeGraph,
    isStack: true,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    tooltip: {
      formatter: (datum) => {
        return { 
          title: `${datum.date} (${btcMap[datum.date]})`,
          name: datum.type, 
          value: `${datum.value} (${(datum.value * price['btc2cny']).toFixed(2)}元)` 
        };
      },
    }
  };

  const configGW = {
    data: withdrawalGraph,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
    tooltip: {
      formatter: (datum) => {
        return { 
          name: datum.type, 
          value: `${datum.value.toFixed(2)}元 (${btcMap2[datum.date]} BTC)` 
        };
      },
    }
  }
  
  return (
    <React.Fragment>
      <Message open={open} setOpen={setOpen} severity={"warning"} text={'输入值为空或不正确'} />
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        后台统计
      </Typography>
      <div className={classes.basicBlock}>
        <Typography className={classes.secTitle2} gutterBottom>
          最近十四天每日收益
        </Typography>
        <Column {...configG} />
      </div>
      <div className={classes.basicBlock}>
        <Typography className={classes.secTitle2} gutterBottom>
          最近十四天提现
        </Typography>
        <Column {...configGW} />
      </div>
      <div className={classes.basicBlock}>
        <Typography className={classes.secTitle2} gutterBottom>
          48 小时内提现记录 Top20
        </Typography>
        <Grid alignItems='flex-end' container spacing={3}>
          <Grid item>
            <Button onClick={processRecentWithdraw} variant="contained" color="primary">显示</Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setRecentRows([])} variant="contained" color="secondary">清空</Button>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>排名</TableCell>
              <TableCell className={classes.tableCell}>id</TableCell>
              <TableCell className={classes.tableCell}>提现金额(CNY)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { recentRows.map((row, key) => (
              <TableRow key={row._id}>
                <TableCell className={classes.tableCell}>
                  {key + 1}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  {row._id}
                </TableCell>
                <TableCell className={classes.tableCell}>{ fmoney(row.cnySum.toFixed(2)) }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.basicBlock}>
        <Typography className={classes.secTitle} gutterBottom>
          收益统计
        </Typography>
        <Grid alignItems='flex-end' container spacing={3}>
          <Grid item>
            <TextField
              id="income-startTime"
              label="开始时间"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setIncomeStartTime(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id="income-endTime"
              label="结束时间"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setIncomeEndTime(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button onClick={processIncomeRows} variant="contained" color="primary">查询</Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setIncomeRows([])} variant="contained" color="secondary">清空</Button>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>币种</TableCell>
              <TableCell className={classes.tableCell}>金额</TableCell>
              <TableCell className={classes.tableCell}>转成 BTC</TableCell>
              <TableCell className={classes.tableCell}>转成 CNY</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { incomeRows.map((row) => (
              <TableRow key={row.name}>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell className={classes.tableCell}>{fmoney(row.amount)}</TableCell>
                <TableCell className={classes.tableCell}>
                  {fmoney(row.btc, 3)}{ row.name !== 'btc' && ` BTC (${(row.btcShare * 100).toFixed(2)}%)`}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {fmoney(row.cny, 3)}{ row.name !== 'btc' && ` (${(row.cnyShare * 100).toFixed(2)}%)`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.basicBlock}>
        <Typography className={classes.secTitle2} gutterBottom>
          提现统计
        </Typography>
        <Grid alignItems='flex-end' container spacing={3}>
          <Grid item>
            <TextField
              id="withdraw-starttime"
              label="开始时间"
              type="datetime-local"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setWithdrawStartTime(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id="withdraw-endtime"
              label="结束时间"
              type="datetime-local"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setWithdrawEndTime(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button onClick={processWithdrawRows} variant="contained" color="primary">查询</Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setWithdrawRows([])} variant="contained" color="secondary">清空</Button>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>单位</TableCell>
              <TableCell className={classes.tableCell}>CNY</TableCell>
              <TableCell className={classes.tableCell}>BTC</TableCell>
              <TableCell className={classes.tableCell}>USD</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { withdrawRows.map((row) => (
              <TableRow key={row.baseUnit}>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  {row.baseUnit}
                </TableCell>
                <TableCell className={classes.tableCell}>{ typeof row.cny === 'number' ? fmoney(row.cny.toFixed(2)) : row.cny }</TableCell>
                <TableCell className={classes.tableCell}>{ typeof row.btc === 'number' ? fmoney(row.btc.toFixed(2)) : row.btc }</TableCell>
                <TableCell className={classes.tableCell}>{ typeof row.usd === 'number' ? fmoney(row.usd.toFixed(2)) : row.usd }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <div className={classes.basicBlock}>
        <Typography className={classes.secTitle2} gutterBottom>
          提现排行榜
        </Typography>
        <Grid alignItems='flex-end' container spacing={3}>
          <Grid item>
            <TextField
              id="withdraw-starttime"
              label="开始时间"
              type="datetime-local"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setRankStartTime(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              id="withdraw-endtime"
              label="结束时间"
              type="datetime-local"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setRankEndTime(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Select
              native
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
            >
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Select>
          </Grid>
          <Grid item>
            <Button onClick={processRankRows} variant="contained" color="primary">查询</Button>
          </Grid>
          <Grid item>
            <Button onClick={() => setRankRows([])} variant="contained" color="secondary">清空</Button>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>排名</TableCell>
              <TableCell className={classes.tableCell}>id</TableCell>
              <TableCell className={classes.tableCell}>提现金额(CNY)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { rankRows.map((row, key) => (
              <TableRow key={row._id}>
                <TableCell className={classes.tableCell}>
                  {key + 1}
                </TableCell>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  {row._id}
                </TableCell>
                <TableCell className={classes.tableCell}>{ fmoney(row.cnySum.toFixed(2)) }</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  )
}