import React, {useState, useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default function ReferralDaily({data, btc2cny}) {

  const [rows, setRows] = useState([]);
  const [hideZero, setHideZero] = useState(true);

  useEffect(() => {
    const temp = [];
    for (let index = 0; index < data.length; index++) {
      const tempDate = new Date(data[index].date);
      temp.push({
        id: index,
        date:  (Date.now() - tempDate < 86400000) ? '今天' : `${tempDate.getFullYear()}年${tempDate.getMonth() + 1}月${tempDate.getDate()}日${tempDate.getHours()}点`,
        childId: data[index].childId,
        btcBalance: (data[index].btcBalance || 0) / 1e8,
        cny: ((data[index].btcBalance || 0) / 1e8 * btc2cny).toFixed(2) + '元',
      });
    }
    setRows(temp);
  }, [data, btc2cny]);

  const handleHideZero = (event) => {
    setHideZero(event.target.checked);
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        推荐日收益
      </Typography>
      <FormControlLabel
        control={
          <Switch
            checked={hideZero}
            onChange={handleHideZero}
            name="hideZero"
            color="primary"
          />
        }
        label="隐藏零收益"
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日期</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>转换 CNY</TableCell>
            <TableCell>BTC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.filter(v => {return (v.btcBalance > 0 || !hideZero)}).map((row) => (
            <TableRow key={row.childId + row.date}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.childId}</TableCell>
              <TableCell>{row.cny}</TableCell>
              <TableCell>{row.btcBalance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}