import { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import lightBlue from '@material-ui/core/colors/lightBlue';
import axios from "axios";
import config from './config';

import './Signin.css';

let once = false;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: lightBlue[600],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
   },
    color: '#ffffff',
    fontSize: '18px',
    height: '50px',
  },
  getCode: {
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
    },
    color: '#ffffff !important',
    fontSize: '16px',
    height: '52px',
    marginTop: "8px",
  }
}));

export default function SignIn({ loginCallback }) {
  const classes = useStyles();
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [parentId, setParentId] = useState('');
  const [sended, setSended] = useState(false);
  const [sec, setSec] = useState(null);
  const [loginType, setLoginType] = useState('code');
  const [pwd, setPwd] = useState('');

  const reqSmsCode = async () => {
    setSended(true);
    const ret = await axios.post(`${config.API}/v1/user/reqSmsCode`, {
      phone,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      return;
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      setSended(false);
      alert('手机号格式错误');
    } else {
      setSended(false);
      alert('未知错误');
    }
  }

  const loginRegisterByPhone = async () => {
    let ret;
    if (parentId) {
      if (!parentId.startsWith('SKYP')) {
        alert('推荐人 ID 格式错误，需要以 SKYP 为开头');
        return;
      }
      ret = await axios.get(`${config.API}/v1/user/loginRegisterByPhone?phone=${phone}&code=${code}&parentId=${parentId}`, {
        withCredentials: true,
      });
    } else if (sec) {
      ret = await axios.get(`${config.API}/v1/user/loginRegisterByPhone?phone=${phone}&code=${code}&sec=${sec}`, {
        withCredentials: true,
      });
    } else {
      ret = await axios.get(`${config.API}/v1/user/loginRegisterByPhone?phone=${phone}&code=${code}`, {
        withCredentials: true,
      });
    }
    if (ret.status === 200 && ret.data && ret.data.success) {
      loginCallback(ret.data.id, phone, ret.data.hub || false);
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      alert('验证码错误');
    } else {
      alert('未知错误');
    }
  }

  const loginByPassword = async () => {
    const ret = await axios.get(`${config.API}/v1/user/loginByPassword`, {
      params: {
        phone,
        password: pwd
      },
      withCredentials: true,
    });
    if (!ret.data.success) {
      return alert('用户名或密码不正确');
    }
    loginCallback(ret.data.id, phone, ret.data.hub || false);
  }

  const checkSec = () => {
    const splits = window.location.href.split('?');
    if (splits.length < 2) return;
    const splitsSec = splits[1].split('=');
    if (splitsSec[0] !== 'sec') return;
    setSec(splitsSec[1]);
  }

  const checkBrowser = () => {
    const issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
    if (issafariBrowser && !once) {
      once = true;
      alert('推荐使用 UC 浏览器保持登入状态');
    }
  }

  const changeLoginType = (type) => {
    setLoginType(type);
  }

  useEffect(() => {
    checkSec();
    checkBrowser();
    // eslint-disable-next-line
  }, []);

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          手机号登录
        </Typography>
        {
          loginType === 'code' &&
          <div 
            className='subtitle'
            >
            不想使用验证码？试试
            <div 
              onClick={() => {changeLoginType('pwd')}}
              className='pw-login'
            >密码登录</div>
          </div>
        }
        {
          loginType === 'pwd' &&
          <div 
            className='subtitle'
            >
            没有设置密码？使用
            <div 
              onClick={() => {changeLoginType('code')}}
              className='pw-login'
            >验证码登录</div>
          </div>
        }
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="手机号"
            name="phone"
            autoComplete="phone"
            autoFocus
            value={phone}
            onChange={(event) => {setPhone(event.target.value)}}
            error={false}
            helperText=""
          />
          {
            loginType === 'code' &&
            <Grid
              container
              spacing={2}
              alignItems="center"
              justify="space-between"
            >
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="code"
                  label="验证码"
                  id="code"
                  value={code}
                  onChange={(event) => {setCode(event.target.value)}}
                  error={false}
                  helperText=""
                />
              </Grid>
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  className={classes.getCode}
                  onClick={() => { reqSmsCode() }}
                  disabled={sended}
                >
                  {sended ? '已发送' : '获取验证码'}
                </Button>
              </Grid>
            </Grid>
          }
          {
            loginType === 'pwd' &&
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="pwd"
              type='password'
              label="密码"
              id="pwd"
              value={pwd}
              onChange={(event) => {setPwd(event.target.value)}}
              error={false}
              helperText=""
            />
          }
          

          {!sec && loginType === 'code' && <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="parentId"
            label="推荐人ID（可选）"
            name="parentId"
            autoFocus
            value={parentId}
            onChange={(event) => {setParentId(event.target.value)}}
            error={false}
            helperText=""
          />}
          
          <Button
            fullWidth
            variant="contained"
            className={classes.submit}
            onClick={loginType === 'code' ? () => { loginRegisterByPhone() } : () => { loginByPassword() }}
          >
            {
            loginType === 'code' ? '登录/注册' : '登录'
            }
          </Button>
        </form>
      </div>
    </Container>
  );
}