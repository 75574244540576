import React from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import Button from '@material-ui/core/Button';
import config from '../config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import copy from "copy-to-clipboard";
import Message from "../component/Message";

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
  copyBtnCon: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  copyBtn: {
    fontSize: '15px',
    marginLeft: '15px',
    cursor: 'pointer'
  }
});

export default function Balance({ id, unit }) {
  const classes = useStyles();

  const [balanceCNY, setBalanceCNY] = useState(0);
  const [balanceUSD, setBalanceUSD] = useState(0);
  const [balanceBTC, setBalanceBTC] = useState(0);
  const [withdrawCheck, setWithdrawCheck] = useState(false);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const handleWithdrawCheckOpen = () => {
    setWithdrawCheck(true);
  };

  const handleWithdrawCheckClose = () => {
    setWithdrawCheck(false);
  };

  const getBalance = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getBalance?id=${id}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setBalanceCNY(ret.data.data.cny);
      setBalanceUSD(ret.data.data.usd || 0);
      setBalanceBTC(ret.data.data.balance);
      window.transfer.balanceBTC = ret.data.data.balance;
    }
  }

  const withdrawBalance = async () => {
    setWithdrawCheck(false);

    if (unit === 'cny') {
      const cny = parseFloat(balanceCNY);
      if (cny >= 1){
        const ret = await axios.get(`${config.API}/v1/aposimz/withdrawBalance?baseUnit=cny`, { withCredentials: true });
        if (ret.status === 200 && ret.data && ret.data.success) {
          alert('提现成功：将转账到手机号对应支付宝账户');
          setTimeout(() => {
            getBalance();
          }, 500);
        } else if (ret.status === 200 && ret.data && !ret.data.success) {
          alert(`提现失败：${ret.data.data}`);
        } else {
          alert('提现失败：网络请求失败');
        }
      } else {
        alert('提现失败：提现余额最低 100 元');
      }
    } else if (unit === 'usd') {
      const usd = parseFloat(balanceUSD);
      if (usd >= 20){
        const ret = await axios.get(`${config.API}/v1/aposimz/withdrawBalance?baseUnit=usd`, { withCredentials: true });
        if (ret.status === 200 && ret.data && ret.data.success) {
          alert('提现成功：将转账到对应 TRC20 USDT 地址');
          setTimeout(() => {
            getBalance();
          }, 500);
        } else if (ret.status === 200 && ret.data && !ret.data.success) {
          alert(`提现失败：${ret.data.data}`);
        } else {
          alert('提现失败：网络请求失败');
        }
      } else {
        alert('提现失败：提现余额最低 20 美元');
      }
    }
  }

  const copyText = () => {
    if (copy(id)) {
      setText('复制成功');
      setOpen(true);
    } else {
      setText('复制失败')
      setOpen(true);
    }
  }

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Message open={open} setOpen={setOpen} severity={"success"} text={text} />
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        账户余额
      </Typography>
      <Typography component="p" variant="h4">
        {unit === 'cny' ?
        parseFloat(balanceCNY).toFixed(2) + ' 元' :
        '$' + parseFloat(balanceUSD).toFixed(2) + ' USD'}
      {unit === 'cny' &&
      <Button variant="contained" onClick={handleWithdrawCheckOpen} color="primary" size="small" style={{marginLeft: '8px', width: '90px'}}>
        支付宝提现
      </Button>}
      {unit === 'usd' &&
      <Button variant="contained" onClick={handleWithdrawCheckOpen} color="primary" size="small" style={{marginLeft: '8px', width: '90px'}}>
        USDT 提现
      </Button>}
      <Dialog
        open={withdrawCheck}
        onClose={handleWithdrawCheckClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{unit === 'cny' ? '支付宝提现' : 'USDT 提现'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {unit === 'cny' ?
          '点击确认，将按照按点击时 BTC 价格转换。1天内到账。' :
          '点击确认，将按照按点击时 BTC 价格转换，提现到设置的 TRC20 USDT 地址，5分钟内到账。'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleWithdrawCheckClose} color="primary">
            取消
          </Button>
          <Button onClick={withdrawBalance} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
      </Typography>
      <Typography component="p" variant="subtitle1" className={classes.depositContext}>
        {parseFloat(balanceBTC / 1e8).toFixed(8) + ' BTC'} 
      </Typography>
      <p style={{margin: 0, marginBottom: '8px', fontSize: '12px', color: '#555'}}>
        {unit === 'cny' ?
        '提现按点击时 BTC 价格转换，1天内到账。':
        '提现按点击时 BTC 价格转换，5分钟内到账。'
        }
      </p>
      <div className={classes.copyBtnCon}>
        {`ID: ${id}`} 
        <FileCopyOutlinedIcon onClick={copyText} className={classes.copyBtn} />
      </div>
    </React.Fragment>
  );
}
