import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from "axios";
import config from './config';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


export default function RemoteSetting({ id }) {
  const [forbidMining, setForbidMining] = useState(false);

  const [changeCheck, setChangeCheck] = useState(false);

  const [checked, setChecked] = useState(false);

  const handleChangeCheckOpen = (e) => {
    setChecked(e.target.checked);
    setChangeCheck(true);
  };

  const handleChangeCheckClose = () => {
    setChangeCheck(false);
  };

  const getUserConfig = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getUserConfig?id=${id}&noCache=1`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      setForbidMining(ret.data.data.forbidMining || false);
    }
  }

  const setForbidMiningConfig = async (value) => {
    const sendValue = value ? 1 : 0;
    await axios.get(`${config.API}/v1/aposimz/setForbidMiningConfig?value=${sendValue}`, { withCredentials: true });
  }

  const handleForbidMining = () => {
    setForbidMining(checked);
    setForbidMiningConfig(checked);
    handleChangeCheckClose();
  };

  useEffect(() => {
    getUserConfig(id);
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        远程群控
      </Typography>
      <p>远程群控功能需要客户端额外配置参数，<a href="https://www.yuque.com/skypool/uox3k7/gcuq29#bUNLg" target="_blank" rel="noreferrer">详见教程</a></p>
      <FormControlLabel
        control={
          <Switch
            checked={forbidMining}
            onChange={handleChangeCheckOpen}
            name="forbidMining"
            color="primary"
          />
        }
        label="远程关闭所有矿机"
      />
      <Dialog
        open={changeCheck}
        onClose={handleChangeCheckClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {forbidMining ? 
          '远程群控' 
          : '远程群控'
          }
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {forbidMining ? 
          '点击确认，将远程允许所有挖矿进程执行，请慎重点击确认。' 
          : '点击确认，将远程关闭所有挖矿进程，请慎重点击确认。'
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeCheckClose} color="primary">
            取消
          </Button>
          <Button onClick={handleForbidMining} color="primary" autoFocus>
            确认
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}