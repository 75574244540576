import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import lightBlue from '@material-ui/core/colors/lightBlue';
import grey from '@material-ui/core/colors/grey';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: grey[900],
  },
  text: {
    color: grey[100],
  },
  link: {
    color: lightBlue[200],
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth="sm">
        {/* <Typography variant="body2" className={classes.text}>
          严禁使用本软件在非个人主权资产设备上使用，违者偷挖承担一切法律后果，个人信息将被提交公安部门
        </Typography> */}
        <Typography variant="body2" className={classes.text}>
          {'Copyright ©'}
          <Link href="https://bar.skypool.xyz" className={classes.link}>
            skypool.xyz
          </Link>{' 2018 - 2024'}
        </Typography>
        {/* <Typography variant="body2" className={classes.text}>
          沪ICP备18001040号-1
        </Typography> */}
      </Container>
    </footer>
  );
}