import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  priceRow: {
    fontSize: '15px'
  }
}));

export default function Price({data, unit}) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container justify="space-between" alignItems="center" direction="row">
        <Grid item xs={7}>
          <Typography component="h2" variant="h6" color="primary" gutterBottom style={{paddingRight: "30px"}}>
            当前币价
          </Typography>
        </Grid>
        <Grid item xs={5}>
        <p style={{color:"#888", fontSize:"8px"}}>coinmarketcap.com</p>
        </Grid>
      </Grid>
      <Typography className={classes.priceRow} component="p" variant="body1">
        {unit === 'cny' ? `1 BTC = ${data.btc2cny.toFixed(2)} 元` : `1 BTC = $ ${data.btc2usd.toFixed(2)}` }
      </Typography>
      <Typography className={classes.priceRow} component="p" variant="body1">
        {unit === 'cny' ? `1 ETC = ${(data.etc2btc*data.btc2cny).toFixed(2)} 元` : `1 ETC = $ ${(data.etc2btc*data.btc2usd).toFixed(2)}` }
      </Typography>
      <Typography className={classes.priceRow} component="p" variant="body1">
        {unit === 'cny' ? `1 ERG = ${(data.erg2btc*data.btc2cny || 0).toFixed(2)} 元` : `1 ERG = $ ${(data.erg2btc*data.btc2usd || 0).toFixed(2)}` }
      </Typography>
      <Typography className={classes.priceRow} component="p" variant="body1">
        {unit === 'cny' ? `1 CFX = ${(data.cfx2btc*data.btc2cny || 0).toFixed(2)} 元` : `1 CFX = $ ${(data.cfx2btc*data.btc2usd || 0).toFixed(2)}` }
      </Typography>
      <Typography className={classes.priceRow} component="p" variant="body1">
        {unit === 'cny' ? `1 KAS = ${(data.kas2btc*data.btc2cny || 0).toFixed(2)} 元` : `1 KAS = $ ${(data.kas2btc*data.btc2usd || 0).toFixed(3)}` }
      </Typography>
      <Typography className={classes.priceRow} component="p" variant="body1">
      {unit === 'cny' ? `1 XMR = ${(data.xmr2btc*data.btc2cny).toFixed(2)} 元` : `1 XMR = $ ${(data.xmr2btc*data.btc2usd).toFixed(2)}` }
      </Typography>
      <Typography className={classes.priceRow} component="p" variant="body1">
        {unit === 'cny' ? `1 RVN = ${(data.rvn2btc*data.btc2cny).toFixed(2)} 元` : `1 RVN = $ ${(data.rvn2btc*data.btc2usd).toFixed(2)}` }
      </Typography>
    </React.Fragment>
  );
}
