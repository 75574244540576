import React from "react";
import Typography from '@material-ui/core/Typography';
import { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import FileCopy from '@material-ui/icons/FileCopy';
import axios from "axios";
import config from '../config';
import AccountBox from '@material-ui/icons/AccountBox';
import TextField from '@material-ui/core/TextField';
import lightBlue from '@material-ui/core/colors/lightBlue';


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 60
  },
  tableCell: {
    width: 130,
    height: 40
  },
  input: {
    width: 130,
    height: 40
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
   },
    color: '#ffffff',
    fontSize: '18px',
    height: '50px',
  },
}));

const CustomTableCell = ({ row, name, onChange }) => {
  const classes = useStyles();
  const { isEditMode } = row;
  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode && name !== 'id' ? (
        <Input
          value={row[name]}
          name={name}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row[name]
      )}
    </TableCell>
  );
};

const createData = (comment, id, password, phone, percentage, alipayNo, name) => ({
  comment: comment || '',
  id,
  password: password || '',
  phone: phone || '',
  percentage: Math.round(parseFloat(percentage) * 100),
  alipayNo: alipayNo || '',
  name: name || '',
  isEditMode: false
});

export default function HubManager({ hubId, isHub }) {
  const [rows, setRows] = useState([]);
  const [previous, setPrevious] = React.useState({});
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [barNum, setBarNum] = useState(0);
  const [inRequest, setInRequest] = useState(false);
  const classes = useStyles();

  const onToggleEditMode = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onToggleEditModeDone = id => {
    setRows(state => {
      return rows.map(row => {
        if (row.id === id) {
          setSub(row);
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {
    if (!previous[row.id]) {
      setPrevious(state => ({ ...state, [row.id]: row }));
    }
    const value = e.target.value;
    const name = e.target.name;
    const { id } = row;
    const newRows = rows.map(row => {
      if (row.id === id) {
        return { ...row, [name]: value };
      }
      return row;
    });
    setRows(newRows);
  };

  const onRevert = id => {
    const newRows = rows.map(row => {
      if (row.id === id) {
        return previous[id] ? previous[id] : row;
      }
      return row;
    });
    setRows(newRows);
    setPrevious(state => {
      delete state[id];
      return state;
    });
    onToggleEditMode(id);
  };

  const createSub = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/createSub?hubId=${hubId}`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      // 创建成功
      await getAllSub();
    } else {
      let errMsg;
      if (ret.data.data === 'exceed sub number limit') {
        errMsg = '子账户创建不能超过 300 个';
      } else {
        errMsg = ret.data.data;
      }
      alert(`创建子账户失败：${errMsg}`);
    }
  }

  const setSub = async (row) => {
    let params = '';
    if (row.alipayNo) {
      params += `&alipayNo=${row.alipayNo}`;
    }
    if (row.comment) {
      params += `&comment=${row.comment}`;
    }
    if (row.password) {
      params += `&password=${row.password}`;
    }
    if (row.phone) {
      params += `&phone=${row.phone}`;
    }
    if (row.percentage) {
      params += `&percentage=${parseFloat(row.percentage) / 100}`;
    }
    if (row.name) {
      params += `&name=${row.name}`;
    }
    const ret = await axios.get(`${config.API}/v1/aposimz/setSub?subId=${row.id}` + params, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      // 修改成功
    } else {
      alert(`修改子账户失败：${ret.data.data}`);
    }
  }

  const getAllSub = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getAllSub?hubId=${hubId}`, {
      withCredentials: true,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      // 获取成功
      const newDate = [];
      for (const i in ret.data.data) {
        const v = ret.data.data[i];
        newDate.push(createData(v.comment, v.subId, v.password, v.phone, v.percentage, v.alipayNo, v.name));
      }
      setRows(newDate);
    } else {
      alert(`获取子账户失败：${ret.data.data}`);
    }
  }

  const hubPermissionRequest = async () => {
    if (name.length > 10) {
      alert('姓名过长');
      return;
    }
    if (name.length === 0) {
      alert('未填姓名');
      return;
    }
    if (city.length > 40) {
      alert('城市名过长');
      return;
    }
    if (city.length === 0) {
      alert('未填城市');
      return;
    }
    if (barNum === '0') {
      alert('网吧数量不能为 0');
      return;
    }
    const ret = await axios.get(`${config.API}/v1/aposimz/hubPermissionRequest?id=${hubId}&name=${name}&city=${city}&barNum=${barNum}`);
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert('提交申请成功');
    } else {
      alert(`提交申请失败 ${ret.data.data}`);
    }
    getHubPermissionRequest();
  }

  const getHubPermissionRequest = async () => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getHubPermissionRequest?id=${hubId}`);
    if (ret.status === 200 && ret.data && ret.data.data) {
      setInRequest(true);
    } else {
      setInRequest(false);
    }
  }

  useEffect(() => {
    getAllSub();
    if (!isHub) {
      getHubPermissionRequest();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
    {isHub &&
    <div>
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      子账户列表
    </Typography>
      <p>子账户用户登录页面 <a href="https://netbar.kumapaw.com" target="_blank" rel="noreferrer">https://netbar.kumapaw.com</a>，有问题联系天池群管理员。</p>
      <p>一键登录链接可以复制粘贴发送给子账户网吧业主</p>
      <Table className={classes.table} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell align="left" />
            <TableCell align="left">网吧备注</TableCell>
            <TableCell align="left">ID</TableCell>
            <TableCell align="left">登录密码</TableCell>
            <TableCell align="left">手机号</TableCell>
            <TableCell align="left">抽水比例 %</TableCell>
            <TableCell align="left">支付宝账户(邮箱/手机)</TableCell>
            <TableCell align="left">支付宝实名</TableCell>
            <TableCell align="left">登录</TableCell>
            <TableCell align="left">链接</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                  <>
                    <IconButton
                      aria-label="done"
                      onClick={() => onToggleEditModeDone(row.id)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      aria-label="revert"
                      onClick={() => onRevert(row.id)}
                    >
                      <RevertIcon />
                    </IconButton>
                  </>
                ) : ((
                  <IconButton
                    aria-label="edit"
                    onClick={() => onToggleEditMode(row.id)}
                  >
                    <EditIcon />
                  </IconButton>
                ))}
              </TableCell>
              <CustomTableCell {...{ row, name: "comment", onChange }} />
              <CustomTableCell {...{ row, name: "id", onChange }} />
              <CustomTableCell {...{ row, name: "password", onChange }} />
              <CustomTableCell {...{ row, name: "phone", onChange }} />
              <CustomTableCell {...{ row, name: "percentage", onChange }} />
              <CustomTableCell {...{ row, name: "alipayNo", onChange }} />
              <CustomTableCell {...{ row, name: "name", onChange }} />
              <TableCell className={classes.selectTableCell}>
                <IconButton
                  aria-label="login"
                  onClick={() => window.open(`https://api.netbar.skypool.xyz/v1/user/loginSubByPassword?redirect=1&id=${row.id}&password=${row.password}`, '_blank')}
                >
                  <AccountBox />
                </IconButton>
              </TableCell>
              <TableCell className={classes.selectTableCell}>
                <IconButton
                  aria-label="login"
                  onClick={() => {
                    navigator.clipboard.writeText(`https://api.netbar.skypool.xyz/v1/user/loginSubByPassword?redirect=1&id=${row.id}&password=${row.password}`);
                    alert(`已复制 ${row.id} 一键登录链接到剪贴板`);
                  }}
                >
                  <FileCopy />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <br/>
      <p>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        startIcon={<AddIcon />}
        onClick={createSub}
      >
          创建
      </Button>
      </p>
    </div>
    }
    {!isHub && !inRequest &&
    <div>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        申请开通网维权限
      </Typography>
      <a href="https://www.yuque.com/skypool/uox3k7/rx08ph" target="_blank" rel="noreferrer">点击阅读网维管理指南</a>
      <p>网维权限开通申请提交后，通常最多会在12小时内审核完毕。如果需要加急审核请加入天池网吧QQ群联系群主。</p>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="网维管理者本人姓名"
          name="name"
          autoComplete="name"
          autoFocus
          value={name}
          onChange={(event) => {setName(event.target.value)}}
          error={false}
          helperText=""
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="city"
          label="城市"
          name="city"
          autoComplete="city"
          autoFocus
          value={city}
          onChange={(event) => {setCity(event.target.value)}}
          error={false}
          helperText=""
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="barNum"
          label="网吧数量"
          name="barNum"
          autoComplete="barNum"
          autoFocus
          value={barNum}
          onChange={(event) => {setBarNum(event.target.value)}}
          error={false}
          helperText=""
        />
        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={() => { hubPermissionRequest() }}
        >
          提交
        </Button>
      </form>
      <br/>
    </div>
    }
    {!isHub && inRequest &&
      <div>
        <Typography component="h2" variant="h6" color="primary" gutterBottom>
          已提交权限申请，等待审核中......
        </Typography>
        <a href="https://www.yuque.com/skypool/uox3k7/rx08ph" target="_blank" rel="noreferrer">点击阅读网维管理指南</a>
        <p>网维权限开通申请提交后，通常最多会在12小时内审核完毕。如果需要加急审核请加入天池网吧QQ群联系群主。</p>
      </div>
    }
    </React.Fragment>
  );
}
