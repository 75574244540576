import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import lightBlue from '@material-ui/core/colors/lightBlue';
import axios from "axios";
import config from './config';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
   },
    color: '#ffffff',
    fontSize: '18px',
    height: '50px',
  },
  getCode: {
    backgroundColor: lightBlue[600],
    '&:hover': {
      background: lightBlue[700],
    },
    color: '#ffffff !important',
    fontSize: '16px',
    height: '52px',
    marginTop: "8px",
  }
}));

export default function UserConfig({ phone, id }) {
  const classes = useStyles();

  const [sended, setSended] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwd2, setPwd2] = useState('');
  const [code, setCode] = useState('');

  const reqSmsCode = async () => {
    setSended(true);
    const ret = await axios.post(`${config.API}/v1/user/reqSmsCode`, {
      phone,
    });
    if (ret.status === 200 && ret.data && ret.data.success) {
      alert(`验证码已发送到手机号 ${phone}`);
      return;
    } else if (ret.status === 200 && ret.data && !ret.data.success) {
      setSended(false);
      alert('手机号格式错误');
    } else {
      setSended(false);
      alert('未知错误');
    }
  }

  const changePwd = async () => {
    if (pwd === '') {
      return alert("请输入密码");
    }
    if (pwd2 === '') {
      return alert("请输入确认密码");
    }
    if (pwd !== pwd2) {
      return alert("两次输入的密码不一致");
    }
    if (!/^[0-9]{6}$/.test(code)) {
      return alert("验证码格式错误");
    }
    const ret = await axios.get(`${config.API}/v1/user/updatePassword`, {
      params: {
        phone,
        code,
        password: pwd
      },
      withCredentials: true
    })
    if (ret.data.success) {
      alert('修改成功');
    } else {
      alert('验证码错误');
    }
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        修改密码
      </Typography>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          type="password"
          fullWidth
          id="pwd"
          label="新密码"
          name="pwd"
          autoComplete="pwd"
          value={pwd}
          autoFocus
          onChange={(event) => {setPwd(event.target.value)}}
          error={false}
          helperText=""
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          type="password"
          fullWidth
          id="pwd2"
          label="确认新密码"
          name="pwd2"
          autoComplete="pwd2"
          value={pwd2}
          onChange={(event) => {setPwd2(event.target.value)}}
          error={false}
          helperText=""
        />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={8}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="code"
              label="登录手机号的验证码"
              id="code"
              value={code}
              onChange={(event) => {setCode(event.target.value)}}
              error={false}
              helperText=""
            />
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              className={classes.getCode}
              onClick={() => { reqSmsCode() }}
              disabled={sended}
            >
              
              {sended ? '已发送' : '获取验证码'}
            </Button>
          </Grid>
        </Grid>
        <Button
          fullWidth
          variant="contained"
          className={classes.submit}
          onClick={() => { changePwd() }}
        >
          修改密码
        </Button>
      </form>
    </React.Fragment>
  );
}