import React, {useEffect, useState} from 'react';
import { ComposedChart, Line, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import Typography from '@material-ui/core/Typography';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import config from '../config';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  statsBox: {
    display: 'flex',
    textAlign: 'right',
    fontSize: '15px',
    fontWeight: '400',
    width: '100%'
  },
  statsBoxLeft: {
    display: 'flex',
    textAlign: 'left',
    fontSize: '15px',
    fontWeight: '400',
    width: '100%'
  },
  figure: {
    fontWeight: '600'
  }
}));

export default function Chart({id, name, data}) {
  const classes = useStyles();
  const matches = useMediaQuery('(min-width:600px)');

  const [chartData, setChartData] = useState([]);
  const [unit, setUnit] = useState('MH/s');
  const [day, setDay] = useState(1);
  const [aveHash, setAveHash] = useState(0);
  const [aveMachine, setAveMachine] = useState(0);

  const renewData = async (id, day) => {
    const ret = await axios.get(`${config.API}/v1/aposimz/getDayWorkerStatus?id=${id}&day=${day}&coinType=${name}`);
    if (ret.status === 200 && ret.data && ret.data?.success) {
      processRawData(ret.data.data || []);
    }
  }

  const processRawData = (inputData) => {
    const rawData = inputData[name];
    if (!rawData) {
      return;
    }
    const detailData = [];
    let hashSum = 0;
    let machineSum = 0;
    rawData.map((v) => {
      const tempDate = new Date(v.date);
      let newV;
      let hashrate;
      if (name === 'xmr') {
        hashrate = parseFloat((v.hashrate / 1000).toFixed(2));
        newV = {
          workerCount: v.workerCount,
          date: `${tempDate.getHours()}:${tempDate.getMinutes() || '00'}`,
          hashrate,
        }
      } else {
        hashrate = parseFloat(v.hashrate.toFixed(2));
        newV = {
          workerCount: v.workerCount,
          date: `${tempDate.getHours()}:${tempDate.getMinutes() || '00'}`,
          hashrate,
        }
      }
      hashSum += hashrate;
      machineSum += v.workerCount;
      detailData.push(newV);
      return v;
    });
    const count = rawData.length;
    const aveHash = hashSum / count;
    const aveMachine = machineSum / count;
    detailData.reverse();
    setChartData(detailData);
    setAveHash(aveHash);
    setAveMachine(aveMachine);
  }

  useEffect(() => {
    processRawData(data);
    switch(name) {
      case 'etc':
      case 'rvn':
      case 'erg':
      case 'cfx':
      case 'kas':
        setUnit('MH/s');
        break;
      case 'xmr':
        setUnit('KH/s');
        break;
      default:
        setUnit('MH/s');
        break;
    }
    // eslint-disable-next-line
  }, [name, data]);

  return (
    <React.Fragment>
      <Grid container spacing={3} justify="space-between" alignItems="center" direction="row">
        <Grid item sm={3} xs={12}>
        <Typography component="h2" variant="h6" color="primary" gutterBottom style={{paddingRight: "30px"}}>
          {name.toUpperCase()} 算力矿机图表
        </Typography>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid container className={!matches ? classes.statsBoxLeft : classes.statsBox}>
            <Grid item sm={6} xs={12}>
              图表平均算力: <span className={classes.figure}>{ aveHash.toFixed(2) }</span> { unit }
            </Grid>
            <Grid item sm={6} xs={12}>
              图表平均矿机: <span className={classes.figure}>{ aveMachine.toFixed(1) }</span> 台
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={3} xs={12}>
        <ToggleButtonGroup
          style={{float: "right"}}
          value={day}
          exclusive
          onChange={(event, value) => { if (value === null) return; setDay(value); renewData(id, value); }}
          aria-label="text alignment"
        >
          <ToggleButton value={1} aria-label="1">
            24h
          </ToggleButton>
          <ToggleButton value={2} aria-label="2">
            48h
          </ToggleButton>
          <ToggleButton value={3} aria-label="3">
            72h
          </ToggleButton>
        </ToggleButtonGroup>
        </Grid>
      </Grid>


      <ResponsiveContainer>
        <ComposedChart
          data={chartData}
          margin={{
            top: 20, right: 20, bottom: 20, left: 20,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />
          <XAxis dataKey="date" />
          <YAxis yAxisId="left">
            <Label position="left" angle={270}>
              {unit}
            </Label>
          </YAxis>
          <YAxis yAxisId="right" orientation="right">
            <Label position="right" angle={90}>
              在线矿机
            </Label>
          </YAxis>
          <Tooltip />
          <Legend />
          <Area yAxisId="left" type="monotone" dataKey="hashrate" name="算力" unit={unit} fill="#b3e5fc" stroke="#0277bd"  strokeWidth={2} />
          <Line yAxisId="right" type="monotone" dataKey="workerCount" name="矿机" stroke="#43a047" strokeWidth={3} dot={false} />
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
}

