import React, {useState, useEffect} from 'react';
import { Pie } from '@ant-design/charts';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles({
  paginationStyle: {
    margin: '25px 10px 5px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  subtitle: {
    display: 'flex',
    width: '100%',
    padding: '5px 2px 15px 15px'
  },
  showBox: {
    display: 'flex',
    width: '100%',
    fontSize: '15px',
    justifyContent: 'flex-start',
    fontWeight: '500',
    alignItems: 'center',
    lineHeight: '30px',
    color: '#404040',
    padding: '10px 0'
  },
  showBoxFigure: {
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '30px',
    color: 'black'
  },
  showBoxBtn: {
    height: '30px',
    width: '50px',
    marginLeft: '15px'
  },
  dialogSubtitle: {
    padding: '5px 15px 0',
    color: '#252525'
  },
  dialogBody: {
    width: '600px',
    height: '400px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  figure: {
    fontWeight: '600'
  }
});

export default function DailyIncome({price, data, count, statsRaw, pageLimit, getDailyIncome, id, unit, btc2cny, btc2usd}) {

  const graphDataCurr = statsRaw.graphDataCurr || [];
  const graphDataPrev = statsRaw.graphDataPrev || [];

  const classes = useStyles();

  const [rows, setRows] = useState([]);
  const [enableETC, setEnableETC] = useState(false);
  const [enableRVN, setEnableRVN] = useState(false);
  const [enableXMR, setEnableXMR] = useState(false);
  const [enableERG, setEnableERG] = useState(false);
  const [enableCFX, setEnableCFX] = useState(false);
  const [enableKAS, setEnableKAS] = useState(false);
  const [stats, setStats] = useState({});
  const [visible, setVisible] = useState(false);
  const [graph, setGraph] = useState('curr');
  const [graphDataCurrNew, setGraphDataCurrNew] = useState([]);
  const [graphDataPrevNew, setGraphDataPrevNew] = useState([]);

  const pageNum = Math.ceil(count / pageLimit);

  const onChange = (event, page) => {
    getDailyIncome(id, page - 1, pageLimit);
  }

  const handleClose = () => setVisible(false);

  useEffect(() => {
    const temp = [];
    let enableETC = false;
    let enableRVN = false;
    let enableXMR = false;
    let enableERG = false;
    let enableCFX = false;
    let enableKAS = false;
    for (let index = 0; index < data.length; index++) {
      const tempDate = new Date(new Date(data[index].date).getTime() + 86400000);
      if (data[index].etc) enableETC = true;
      if (data[index].rvn) enableRVN = true;
      if (data[index].xmr) enableXMR = true;
      if (data[index].erg) enableERG = true;
      if (data[index].cfx) enableCFX = true;
      if (data[index].kas) enableKAS = true;
      temp.push({
        id: index,
        date:  (Date.now() < tempDate) ? '今天' : `${tempDate.getFullYear()}年${tempDate.getMonth() + 1}月${tempDate.getDate()}日${tempDate.getHours()}点`,
        cny: ((data[index].btc || 0) / 1e8 * btc2cny).toFixed(2),
        usd: '$' + ((data[index].btc || 0) / 1e8 * btc2usd).toFixed(2),
        btc: (data[index].btc || 0) / 1e8,
        etc: (data[index].etc || 0) / 1e8,
        rvn: (data[index].rvn || 0) / 1e8,
        xmr: (data[index].xmr || 0) / 1e8,
        erg: (data[index].erg || 0) / 1e8,
        cfx: (data[index].cfx || 0) / 1e8,
        kas: (data[index].kas || 0) / 1e8,
      });
    }
    const graphDataCurrNew = [];
    const graphDataPrevNew = [];
    for (const item of graphDataCurr) {
      graphDataCurrNew.push({ 
        angle: parseFloat((item.angle * price[`${item.label.toLowerCase()}2btc`] * price['btc2cny']).toFixed(4)), 
        label: item.label 
      });
    }
    for (const item of graphDataPrev) {
      graphDataPrevNew.push({ 
        angle: parseFloat((item.angle * price[`${item.label.toLowerCase()}2btc`] * price['btc2cny']).toFixed(4)), 
        label: item.label 
      });
    }
    setGraphDataCurrNew(graphDataCurrNew);
    setGraphDataPrevNew(graphDataPrevNew);
    setEnableETC(enableETC);
    setEnableRVN(enableRVN);
    setEnableXMR(enableXMR);
    setEnableERG(enableERG);
    setEnableCFX(enableCFX);
    setEnableKAS(enableKAS);
    setRows(temp);
    setStats({
      btcSumCurr: (statsRaw.btcSumCurr || 0) / 1e8,
      btcSumPrev: (statsRaw.btcSumPrev || 0) / 1e8,
      cnySumCurr: ((statsRaw.btcSumCurr || 0) / 1e8 * btc2cny).toFixed(2),
      cnySumPrev: ((statsRaw.btcSumPrev || 0) / 1e8 * btc2cny).toFixed(2) ,
      btcSum: (statsRaw.btcSum || 0) / 1e8,
      cnySum: ((statsRaw.btcSum || 0) / 1e8 * btc2cny).toFixed(2),
    });
    // eslint-disable-next-line
  }, [data, btc2cny, btc2usd, statsRaw]);

  const configCurr = {
    appendPadding: 10,
    data: graphDataCurrNew,
    angleField: 'angle',
    colorField: 'label',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: function content(_ref) {
        var percent = _ref.percent;
        return ''.concat((percent * 100).toFixed(0), '%');
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: datum.angle + ' 元' };
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  const configPrev = {
    appendPadding: 10,
    data: graphDataPrevNew,
    angleField: 'angle',
    colorField: 'label',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: function content(_ref) {
        var percent = _ref.percent;
        return ''.concat((percent * 100).toFixed(0), '%');
      },
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    tooltip: {
      formatter: (datum) => {
        return { name: datum.label, value: datum.angle + ' 元' };
      },
    },
    interactions: [{ type: 'element-active' }],
  };

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary" gutterBottom>
        每日收益
      </Typography>
      
      {/* <div className={classes.subtitle}>
        <Grid container>
          <Grid item sm={12} lg={5}>
            <div className={classes.showBox}>
              { (new Date(Date.now())).getMonth() + 1}月收益 
              <div className={classes.showBoxFigure}>
                {stats.btcSumCurr} BTC ≈ <span className={classes.figure}>{stats.cnySumCurr}</span> 元
              </div>
              <Button 
                onClick={() => {setVisible(true);setGraph('curr');}}
                className={classes.showBoxBtn} 
                variant="contained" 
                color="primary"
              >详情</Button>
            </div>
          </Grid>
          <Grid item sm={12} lg={5}>
            <div className={classes.showBox}>
              { (new Date(Date.now())).getMonth() }月收益 
              <div className={classes.showBoxFigure}>
                {stats.btcSumPrev} BTC ≈ <span className={classes.figure}>{stats.cnySumPrev}</span> 元
              </div>
              <Button 
                onClick={() => {setVisible(true);setGraph('prev');}}
                className={classes.showBoxBtn} 
                variant="contained" 
                color="primary"
              >详情</Button>
            </div>
          </Grid>
          <Grid item sm={12} lg={5}>
            <div className={classes.showBox}>
              历史收益 
              <div className={classes.showBoxFigure}>
                {stats.btcSum} BTC ≈ <span className={classes.figure}>{stats.cnySum}</span> 元
              </div>
            </div>
          </Grid>
        </Grid>
      </div> */}

      {/* <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={visible}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          { graph === 'curr' && `${ (new Date(Date.now())).getMonth() + 1 }月收益详情` }
          { graph === 'prev' && `${ (new Date(Date.now())).getMonth() }月收益详情` }
        </DialogTitle>
        <div className={classes.dialogSubtitle}>
          * 该收益分布比例图，是用当前币价进行换算，和实际收益会有差异，仅供参考。
        </div>
        <div className={classes.dialogBody}>
          { graph === 'curr' && <Pie {...configCurr} /> }
          { graph === 'prev' && <Pie {...configPrev} /> }
        </div>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            关闭
          </Button>
        </DialogActions>
      </Dialog> */}

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>日期</TableCell>
            <TableCell>
              总转换 BTC
              <Tooltip title="BTC 数据是矿工具体实时结算的单位币种，矿工挖的所有币种立刻结算为 BTC">
                <IconButton aria-label="info" size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            {unit === 'cny' &&
            <TableCell>
              当前价格 CNY
              <Tooltip title="每天结算的单位是 BTC，该 CNY 价格为当前时间的 BTC 等价价格，如果已提现则该数据没有参考价值，不是提现时的 BTC 等价 CNY 价格">
                <IconButton aria-label="info" size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            }
            {unit === 'usd' &&
            <TableCell>
              当前价格 USD
              <Tooltip title="每天结算的单位是 BTC，该 USD 价格为当前时间的 BTC 等价价格，如果已提现则该数据没有参考价值，不是提现时的 BTC 等价 USD 价格">
                <IconButton aria-label="info" size="small">
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TableCell>
            }
            {enableETC && <TableCell>ETC</TableCell>}
            {enableXMR && <TableCell>XMR</TableCell>}
            {enableERG && <TableCell>ERG</TableCell>}
            {enableCFX && <TableCell>CFX</TableCell>}
            {enableKAS && <TableCell>KAS</TableCell>}
            {enableRVN && <TableCell>RVN</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.btc}</TableCell>
              {unit === 'cny' && (
                <TableCell>
                  {row.cny} 元
                </TableCell>)}
              {unit === 'usd' && <TableCell>{row.usd}</TableCell>}
              {enableETC && <TableCell>{row.etc}</TableCell>}
              {enableXMR && <TableCell>{row.xmr}</TableCell>}
              {enableERG && <TableCell>{row.erg}</TableCell>}
              {enableCFX && <TableCell>{row.cfx}</TableCell>}
              {enableKAS && <TableCell>{row.kas}</TableCell>}
              {enableRVN && <TableCell>{row.rvn}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination className={classes.paginationStyle} count={pageNum} onChange={onChange} />
    </React.Fragment>
  );
}